<template>
  <div
    class="flex flex-nowrap w-full items-center bg-white text-gray-900 shadow-md"
  >
    <div
      v-for="btn in btns"
      :key="btn.title"
      class="btn-tabs"
      :class="{ 'btn-tabs-active': router.currentRoute.name === btn.route }"
    >
      {{ btn.title }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'TabBar',
  data() {
    return { router: this.$router, active: 0 }
  },
  props: {
    btns: {
      type: Array,
      required: true
    }
  }
}
</script>
